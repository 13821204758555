<template>
  <Page :title="'注册'">
    <UserHeader></UserHeader>
    <div class="container white_b register">
      <div class="tit">
        <h2>统一身份注册</h2>
      </div>
      <div class="w-75 m-auto pt-5 register">
        <a-row>
          <a-col :xs={span:24,offset:0}>
            <a-steps>
              <a-step :status="true?'finsh' :'wait'" title="注册账号">
                <a-icon slot="icon" type="solution"/>
              </a-step>
              <a-step :status="StepStatus >= 2?'finsh' :'wat'" title="填写信息">
                <a-icon slot="icon" type="form"/>
              </a-step>
              <a-step :status="StepStatus === 3?'finsh' :'wait'" title="注册完成">
                <a-icon slot="icon" type="smile-o"/>
              </a-step>
            </a-steps>
          </a-col>
        </a-row>
      </div>
      <div v-show="StepStatus === 1">
        <a-form-model
          :model="registerInfo"
          v-bind="layout"
          :rules="smsRules"
          ref="ruleForm"
        >
          <br>
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="18" :offset="1">
              <a-form-model-item label="手机号码" has-feedback prop="phone">
                <a-input
                  v-model="registerInfo.phone"
                  placeholder="仅支持中国大陆(+86)"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="18" :offset="1">
              <a-form-model-item prop="smscode" label="验证码">
                <a-input-search
                  placeholder="短信验证码"
                  @search="sendSmsCode"
                  v-model="registerInfo.smscode"
                >
                  <a-button
                    type="primary"
                    slot="enterButton"
                    :disabled="utilSmsCode.disabled"
                    :class="{ 'back': utilSmsCode.smsStyle }"
                  >
                    <span v-if="utilSmsCode.iconTime > 0 && utilSmsCode.iconTime !== 60">{{ this.utilSmsCode.iconTime }}秒后可再次发送</span>
                    <span v-if="utilSmsCode.iconTime === 60 || utilSmsCode.iconTime === 0">发送验证码</span>
                  </a-button>
                </a-input-search>
              </a-form-model-item>
              <a-col :span="18" :offset="6">
                <a-switch @click="() => {
                  this.utilRegister.switch = !this.utilRegister.switch
                }"> </a-switch>
                我已阅读并同意
                <a href="javascript:void(0)" @click="() => {
                  this.utilRegister.any = true
                }">《XX省公共法律服务平台注册协议》</a
                >
              </a-col>
            </a-col>
            <a-col :span="10">
              <br>
              <a-button @click="register" block>提交</a-button>
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal
          v-model="utilRegister.any"
          title="XX省公共法律服务平台注册协议"
          okText="确认"
          :width=700
          @ok="(e) => {
            this.utilRegister.any = false
          }"
        >
          <Agreement></Agreement>
        </a-modal>
        <br>
      </div>
      <div v-show="StepStatus === 2">
        <a-form-model
          :model="profile"
          v-bind="layout"
          :rules="profileRules"
          ref="ruleForm2"
        >
          <br>
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="18" :offset="1">
              <a-form-model-item label="真实姓名" has-feedback prop="user_name">
                <a-input
                  v-model="profile.user_name"
                  placeholder="请输入您的真实姓名"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="18" :offset="1">
              <a-form-model-item prop="sex" label="性别">
                <a-radio-group v-model="profile.sex" >
                  <a-radio :value="1">
                    男
                  </a-radio>
                  <a-radio :value="0">
                    女
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="18" :offset="1">
              <a-form-model-item prop="idcard_no" label="证件">
                <a-input-group compact>
                  <a-select  v-model="profile.idcard_type" style="width: 20%;">
                    <a-select-option value="身份证">
                      身份证
                    </a-select-option>
                    <a-select-option value="护照">
                      护照
                    </a-select-option>
                  </a-select>
                  <a-input placeholder="证件号码" v-model="profile.idcard_no"  class="a"/>
                </a-input-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="18" :offset="1">
              <a-form-model-item prop="city" label="所在市">
                  <a-select placeholder="请选择市/州" @change="city($event, select.city)" >
                    <a-select-option v-for="value in select.city" :key="value.district_id">
                      {{ value.district_name }}
                    </a-select-option>
                  </a-select>
              </a-form-model-item>
              <a-form-model-item prop="area" label="所在区">
                  <a-select placeholder="请选择区/县" v-model="profile.area" >
                    <a-select-option v-for="value in select.area" :key="value.district_name">
                      {{ value.district_name }}
                    </a-select-option>
                  </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="18" :offset="1">
            <a-form-model-item prop="address" label="详细地址">
              <a-input v-model="profile.address" placeholder="请输入详细地址"></a-input>
            </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <br>
              <a-button @click="profileCommit" block>提交</a-button>
            </a-col>
          </a-row>
        </a-form-model>
        <br>
      </div>
      <div v-show="StepStatus === 3">
        <a-result title="您已注册成功,现在可回到登录页面" status="success">
          <template #extra>
            <a-button key="console" type="primary" @click="goLogin">
              去登录
            </a-button>
          </template>
        </a-result>
      </div>
    </div>
  </Page>
</template>

<script>
import UserHeader from '../../components/business/UserHeader'
import Agreement from './Agreement'
export default {
  components: {
    UserHeader,
    Agreement
  },
  created () {
    this.StepStatus = 1
    this.$api.get('/api/nation').then((res) => {
      this.select.race = res.data.collection
    })
    this.$api.get('/api/city').then((res) => {
      this.select.city = res.data.collection
    })
  },
  name: 'register',
  data () {
    return {
      StepStatus: 1,
      utilPublic: {
        showTime: false
      },
      utilSmsCode: {
        iconTime: 60,
        disabled: true,
        smsStyle: false
      },
      utilRegister: {
        any: false,
        switch: false,
        uid: ''
      },
      select: {
        race: [],
        city: [],
        area: [],
        idType: []
      },
      layout: {
        labelCol: { span: 4, offset: 0 },
        wrapperCol: { span: 16, offset: 0 }
      },
      smscodeInfo: {
        phone: '',
        smscode_name: '',
        smscode_op: '',
        smscode_ttl: 30
      },
      registerInfo: {
        confirm_password: '',
        contacts_alias: '',
        face: '',
        password: '',
        phone: '',
        sex: '',
        smscode: '',
        wxcode: ''
      },
      profile: {
        account: 'renchen',
        address: '',
        age: 0,
        area: '',
        birthday: '',
        city: '',
        confirm_password: '',
        contacts_alias: '',
        create_time: '',
        email: '',
        face: '',
        idcard_no: '',
        idcard_type: '身份证',
        level: '',
        nation: '',
        origin_addres: '',
        origin_area: '',
        origin_city: '',
        origin_province: '',
        password: '',
        phone: '17793417274',
        province: '',
        sex: '',
        smscode: '',
        uid: '',
        update_time: '',
        user_name: '',
        user_type: '',
        wxcode: ''
      },
      smsRules: {
        smscode: [
          { required: true, message: '请输入验证码', trigger: 'change' },
          { len: 6, message: '验证码格式不正确', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          {
            validator: this.enableSendCode,
            trigger: 'change'
          }
        ]
      },
      profileRules: {
        address: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
        age: [
          { required: true, message: '请选择年龄', trigger: 'change' }
        ],
        area: [
          { required: true, message: '请选择区域', trigger: 'change' }
        ],
        city: [
          { required: true, message: '请选择城市', trigger: 'change' }
        ],
        idcard_no: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
          { validator: this.idcard18, message: '格式不正确', trigger: 'change' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        user_name: [
          { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        nation: [
          { required: true, message: '请选择民族', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    sendSmsCode () {
      var that = this
      this.smscodeInfo.phone = this.registerInfo.phone
      this.$api.post('/api/user/smsCode', this.smscodeInfo).then((res) => {
        if (res) {
          this.utilSmsCode.disabled = true
          this.utilSmsCode.smsStyle = true
          this.$message.success('发送成功')
          var interval = setInterval(() => {
            that.utilSmsCode.iconTime--
            if (that.utilSmsCode.iconTime === 0) {
              this.utilSmsCode.disabled = false
              this.utilSmsCode.iconTime = 60
              this.utilSmsCode.smsStyle = false
              clearInterval(interval)
            }
          }, 1000)
        } else {
          this.$message.error('发送失败,请60秒后再试')
        }
      })
    },
    enableSendCode (rule, value, callback) {
      const passwordReg = /^1\d{10}$/
      if (passwordReg.test(value)) {
        this.utilSmsCode.disabled = false
        callback()
      } else {
        this.util.disabled = true
        callback(new Error('请输入正确的手机号'))
      }
    },
    register () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.utilRegister.switch) {
            this.$message.info('请同意注册协议')
            return
          }
          this.$api.post('/api/user/register', this.registerInfo).then((res) => {
            if (res.data.code === 200) {
              this.utilRegister.uid = res.data.data.uid
              this.$message.success('提交成功')
              this.StepStatus = 2
            } else {
              this.$message.error(res.data.content)
            }
          })
        } else {
          return false
        }
      })
    },
    city (value, options) {
      var obj = options.find((item) => {
        return item.district_id === value
      })
      this.profile.city = obj.district_name
      this.$api.get('/api/area?parent=' + value).then((res) => {
        this.select.area = res.data.collection
      })
    },
    // 确认密码
    idcard18 (rule, value, callback) {
      const id = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
      if (id.test(value)) {
        callback()
      } else {
        callback(new Error('格式输入错误'))
      }
    },
    profileCommit () {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          this.profile.phone = this.registerInfo.phone
          this.profile.account = this.registerInfo.phone
          this.profile.uid = this.utilRegister.uid
          this.$api.put('/api/user/update', this.profile).then((res) => {
            if (res.data.code === 200) {
              this.$message.success('提交成功')
              this.StepStatus = 3
            } else {
              this.$message.error('失败')
            }
          })
        } else {
          this.$message.error('有必填项未填写')
          return false
        }
      })
    },
    goLogin () {
      this.$router.push('/login')
    }
  }
}
</script>
<style scoped >
@import url("../../assets/css/member.css");
.back {
  background-color: #999999;
}
.a{
  width: 80%;
  height: 32px;
}
</style>
