<template>
  <div>
    <h5>尊敬的用户：</h5>
    XX省公共法律服务网同意按照本协议规定，向使用本平台的用户提供XX省公共法律网上办事服务。<br>
    为获取XX省公共法律网上办事服务，申办人应当同意本协议的条款并按照页面上的提示完成全部的注册程序，申办人在注册过程中点击“同意”按钮表示完全接受本协议项下的全部条款。<br>
    一、特别说明<br>
    1、为保证您在该平台享受便捷的在线申请、在线预约等服务，请您如实填写在线申请和在线预约的相关办事信息。申办人的基本信息在提交之后不能再擅自修改，因信息填写有误造成的后果，需用户自行承担。<br>
    2、XX省公共法律服务平台对您因没有遵守本协议而造成的损失不负任何法律责任。<br>
    二、服务内容<br>
    目前XX省公共法律服务平台提供了一系列的服务，可以帮助用户快速便捷地进行在线申报、在线预约、在线咨询、评价、建议投诉等操作，个人用户在注册成功之后可享受所有的网上服务。<br>
    三、个人用户注册、密码和安全服务<br>
    1、个人用户申请成为系统用户时，申请人必须提供真实、准确、即时、完整的注册信息；若您提供的信息不真实、不准确、不即时、不完整，对办事过程造成的影响需由用户自行承担。<br>
    2、在注册过程中，个人用户可自主选择一个用户名和密码，并须对其保密性负责，同时对使用该用户名和密码的所有活动负责。<br>
    3、个人注册用户须做到：<br>
    ① 发现非授权使用您的用户名、密码以及其他破坏平台安全性的行为立即向XX省公共法律服务平台或者向12348热线报告；<br>
    ② 确保每次使用XX省公共法律服务平台后正确地离开该站点。<br>
    四、服务终止<br>
    对于用户的以下行为，XX省公共法律服务网有权暂停或终止对其的服务：<br>
    ① 向XX省公共法律服务网的在线建议投诉功能滥发重复信息；<br>
    ② 冒用其他用户的名义发布各种不良信息，办理有损于他人的业务；<br>
    ③ 攻击XX省公共法律服务网的数据、网络或服务；<br>
    ④ 盗用他人在XX省公共法律服务网的用户名或密码。<br>
    五、协议修改<br>
    1、XX省公共法律服务平台将有权随时修改本协议的有关条款，一旦本协议的内容发生变动，平台将会通过适当方式向用户提示修改内容。<br>
    2、如果不同意XX省公共法律服务平台对本协议相关条款所做的修改，用户有权停止使用平台的各项服务。<br>
    3、如果用户继续使用平台提供的服务，则视为其接受XX省公共法律服务平台对本协议相关条款的修改。<br>
  </div>
</template>

<script>
export default {
  name: 'Agreement'
}
</script>

<style scoped>

</style>
